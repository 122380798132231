<template>
  <OneTable :mainHeader="'בוצע'" :postState="'done'" :trucking="trucking" />
</template>

<script>
import OneTable from "@/components/app/common/Table/OneTable2";
export default {
  props: {
    trucking: {
      default: false,
    },
  },
  components: {
    OneTable,
  },
};
</script>

<style>
</style>