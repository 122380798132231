<template>
  <OneTable :mainHeader="'טיוטות'" :postState="'Tamples'"/>
</template>

<script>
import OneTable from "@/components/app/common/Table/OneTable2";
export default {
    components:{
        OneTable,
    }
}
</script>

<style>

</style>