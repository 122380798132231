<template>
  <div>
    <v-progress-linear color="indigo" height="10" indeterminate v-if="loadingTable">
    </v-progress-linear>
    <v-data-table
      :headers="headersFiltered"
      :items="filteredRecords"
      :single-expand="false"
      :expanded.sync="expanded"
      item-key="name"
      class=""
      @click:row="clickRow"
      multi-sort
      :key="updateTable"
      :itemKey="'id'"
      v-if="!loadingTable"
    >
      <template v-slot:top>
        <FilterCard
          :HeadersFiltered="headersFiltered"
          :records="filteredRecords"
          @containsUpdated="containsUpdated"
          @clearFilter="clearFilter"
          @addDialog="addDialog = true"
        />
        <v-toolbar flat>
          <v-toolbar-title>{{ mainHeader }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-3"
            color="red darken-1"
            :disabled="expanded.length === 0"
            text
            @click="expanded = []"
          >
            סגור הכל
          </v-btn>
          <v-dialog scrollable  v-model="addDialog" max-width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                color="deep-orange lighten-2"
                v-bind="attrs"
                v-on="on"
              >
                בחר טורים
                <v-icon right>mdi-format-columns</v-icon>
              </v-btn>
            </template>
            <SelectedColumns
              :title="'טורים'"
              :items="allHeaders"
              @Agree="addAgree"
              @Cancel="addCancel"
            />
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <ExpandedCard
            :item="item"
            :headers="allHeaders"
            :allUsers="allUsers"
            :trucking="trucking"
            @relocateTo="relocateTo"
            @deleteItem="deleteItem"
            @updateItemContent="updateItemContent"
          />
        </td>
      </template>

      <template v-slot:item.fromCity="{ item }">
        <v-icon :dark="false" color="primary" class="mx-1" left
          >mdi-alpha-a-circle</v-icon
        >{{ item.fromCity }}
      </template>
      <template v-slot:item.toCity="{ item }">
        <!-- <v-chip> -->
        <v-icon :dark="false" color="primary" class="mx-1" left
          >mdi-alpha-b-circle</v-icon
        >{{ item.toCity }}
        <!-- </v-chip> -->
      </template>
      <template v-slot:item.startDate="{ item }">
        <!-- <v-chip> -->
        <v-icon :dark="false" color="deep-orange lighten-2" class="mx-1" left
          >mdi-calendar</v-icon
        >{{ item.startDate }}
        <!-- </v-chip> -->
      </template>
      <template v-slot:item.crane="{ item }">
        <v-icon
          :dark="false"
          v-if="item.crane === true || item.crane === 'true'"
          >mdi-tow-truck</v-icon
        >
      </template>
      <template
        v-for="checkBoxeItem in checkBoxeItems"
        v-slot:[`item.${checkBoxeItem.name}`]="{ item }"
      >
        <v-icon
          :key="checkBoxeItem.name"
          color="green"
          :dark="false"
          v-if="
            item[checkBoxeItem.name] === true ||
            item[checkBoxeItem.name] === 'true'
          "
          >mdi-check-bold</v-icon
        >
        <v-icon
          :key="checkBoxeItem.name"
          color="red"
          :dark="false"
          v-if="
            item[checkBoxeItem.name] === false ||
            item[checkBoxeItem.name] === 'false'
          "
          >mdi-close-thick</v-icon
        >
      </template>
      <template v-slot:item.portersNeed="{ item }">
        <v-icon
          :dark="false"
          v-if="item.portersNeed === true || item.crane === 'true'"
          >mdi-account-hard-hat</v-icon
        >
      </template>
      <template v-slot:item.price="{ item }">
        <div v-if="item.price !== 0 && item.price != ''">
          {{ item.price }}
          <span
            :dark="false"
            color="primary"
            class="text--disabled text-caption"
            >ש"ח</span
          >
        </div>
        <div v-else>
          <v-icon :dark="false" color="deep-orange lighten-2" class="mx-1" left
            >mdi-minus</v-icon
          >
        </div>
      </template>
      <template v-slot:item.coments="{ item }">
        <div v-if="item.coments.length">
          {{ item.coments.substring(0, 12) }}
          <span v-if="item.coments.length > 12">...</span>
        </div>

        <!-- </v-chip> -->
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { prepareHeadersForFilteringMixin } from "@/mixins/tables/prepareHeadersForFilteringMixin";

//form elements
import FilterCard from "@/components/app/common/Filters/FilterCard";

import SelectedColumns from "@/components/app/common/Table/elements/SelectedColumns";
import ExpandedCard from "@/components/app/common/Table/elements/ExpandedCard";
export default {
  mixins: [prepareHeadersForFilteringMixin],
  props: {
    mainHeader: {
      default: "כותרת התבלה",
    },
    postState: {
      default: "",
    },
    allUsers: {
      default: false,
    },
    trucking: {
      default: false,
    },
  },
  data() {
    return {
      loadingTable:true,
      updateTable: false,
      expanded: [],
      addDialog: false,
      allHeaders: [],
      // recordsJSON: recordsJSON,
      records: [],
      checkBoxeItems: [{ name: "raiseTail" }, { name: "includeMaam" }],
    };
  },
  computed: {
    ...mapGetters(["loading", "lagguageHeaders"]),
    filteredRecords() {
      if (this.postState !== "") {
        return this.records.filter((v) => {
          if (v.postState === this.postState) {
            return v;
          }
        });
      }
      return this.records;
    },
    headersFiltered() {
      let dessertHeadersFiltered_tmp = this.allHeaders
        .filter((v) => v.selected === true)
        .map((v) => {
          v.modalFilerOpened = false;
          return v;
        });
      return dessertHeadersFiltered_tmp;
    },
  },
  methods: {
    async relocateTo(payload) {
      await this.$store.dispatch("loading", true);
      await this.$store.dispatch("relocateLagguage", {
        val: payload.val,
        sourse: this.postState,
        target: payload.target,
      });
      await this.$store.dispatch("loading", false);
    },
    async deleteItem(item) {
      await this.$store.dispatch("loading", true);
      await this.$store.dispatch("deleteLagguage", {
        val: item,
        sourse: this.postState,
      });
      await this.$store.dispatch("loading", false);
    },
    async updateItemContent(){
      await this.$store.dispatch("loading", true);
      await this.$store.dispatch("loading", false);
    },
    containsUpdated() {
      this.updateTable = !this.updateTable;
    },
    getUniqArrayByKey(theKey, arr) {
      let mappedArr = arr.map((v) => {
        if (v[theKey] !== null && v[theKey] !== undefined) {
          return v[theKey];
        }
      });
      let mappedArrFiltered = mappedArr
        .filter((val) => {
          return val !== undefined;
        })
        .sort();
      let newArr = this._.uniq(mappedArrFiltered);

      return newArr;
    },
    clickRow(obj, item) {
      item.expand(!item.isExpanded);
      // console.log(this.filteredRecords);
    },
    addAgree(newHeadersSelected) {
      this.allHeaders = this.allHeaders.map((element) => {
        if (newHeadersSelected.indexOf(element.text) > -1) {
          element.selected = true;
        } else {
          element.selected = false;
        }
        return element;
      });

      this.addDialog = false;
    },
    addCancel() {
      this.addDialog = false;
    },
    clearFilter(filter) {
      this.updateTable = !this.updateTable;
      if (filter.filterType === "contains") {
        filter.search = null;
      }
      if (filter.filterType === "checkBox") {
        filter.search = null;
      }
      if (filter.filterType === "minMax") {
        filter.minMax = [
          Math.min.apply(
            Math,
            this.filteredRecords.map(function (o) {
              return o[filter.value];
            })
          ) || 0,
          Math.max.apply(
            Math,
            this.filteredRecords.map(function (o) {
              return o[filter.value];
            })
          ) || 0,
        ];
      }
      if (filter.filterType === "date") {
        filter.dateMinMax = ["", ""];
      }
    },
  },
  components: {
    SelectedColumns,
    ExpandedCard,
    FilterCard,
  },
  watch: {
    updateTable() {
      console.log("changed");
    },

    records() {
      console.log("records changed");
    },
  },
  async created() {
    // await this.$store.dispatch("loading", true);
    let Lagguage = await this.$store.dispatch("GetLagguageNewPosts", {
      target: this.postState,
      allUsers: this.allUsers,
      trucking: this.trucking
    });
    this.loadingTable=false
    // await this.$store.dispatch("loading", false);
    this.records = Lagguage || [];
    this.allHeaders = JSON.parse(JSON.stringify(this.lagguageHeaders));
    this.prepareHeaders();
  },
};
</script>