export const prepareHeadersForFilteringMixin = {
    methods: {
        prepareHeaders() {
            this.allHeaders.map((v) => {
                this.clearFilter(v);
                if (!v.filter) {
                    if (v.filterType) {
                        v.filter = function (value) {
                            if (v.filterType === "contains") {
                                if (value === undefined) {
                                    if (v.search) {
                                        return false;
                                    }
                                    return true;
                                }
                                if (v.search && v.search.length) {
                                    if (v.search.includes(value)) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                }
                                return true;
                            }
                            if (v.filterType === "checkBox") {
                                if (value === undefined) {
                                    if (v.search === true || v.search === false) {
                                        return false;
                                    }
                                    return true;
                                }
                                if (v.search === true || v.search === false) {
                                    if (v.search === value) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                }
                                return true;
                            }
                            if (v.filterType === "minMax") {
                                if (value === undefined) {
                                    return true;
                                }
                                if (v.minMax[0] !== 0 || v.minMax[1] !== 9999) {
                                    if (value >= v.minMax[0] && value <= v.minMax[1]) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                }
                                return true;
                            }
                            if (v.filterType === "date") {
                                let correctDateMax = false;
                                let correctDateMin = false;
                                if (v.dateMinMax[0] !== "") {
                                    if (!value || value === 0) {
                                        return false;
                                    }
                                    if (Date.parse(value) >= Date.parse(v.dateMinMax[0])) {
                                        correctDateMin = true;
                                    }
                                } else {
                                    correctDateMin = true;
                                }
                                if (v.dateMinMax[1] !== "") {
                                    if (!value || value === 0) {
                                        return false;
                                    }
                                    if (Date.parse(value) <= Date.parse(v.dateMinMax[1])) {
                                        correctDateMax = true;
                                    }
                                } else {
                                    correctDateMax = true;
                                }
                                if (v.dateMinMax[0] === "" && v.dateMinMax[1] === "") return true;
                                return correctDateMax && correctDateMin;
                            }
                            return true;
                        };
                    }
                }
                return v;
            });
        },
    }
}